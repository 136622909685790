<template>
  <div class="multiple-item-line-wrapper">

    <ItemLine :item="{...items[0],
      name: items[0]?.item?.name, }"/>

    <span v-if="items && items.length - 1 > 0" :id="`tp_item_${teste}`" class="more">+ {{ items.length - 1 }}</span>
    <b-tooltip v-if="items && items.length - 1 > 0" :target="`tp_item_${teste}`" placement="bottom" triggers="hover">
      <div class="tooltip-more" v-for="item in items" :key="item.id">
        <ItemLine :item="{...item,
          name: item?.item.name, }"/>
        <Tiss class="icon" /> {{ item?.health_plan.company_name }} 
      </div>
    </b-tooltip>
  </div>
</template>
<script>

import ItemLine from '@/components/General/ItemLine'
import Tiss from '@/assets/icons/tiss.svg'

export default {
  props: {
    items: Array,
    teste: String,
  },
  components: {
    ItemLine,
    Tiss,
  },
}
</script>
<style lang="scss" scoped>
.multiple-item-line-wrapper {
  width: 300px;
  display: block;
  align-items: center;
  gap: 5px;
}

.more {
    background-color: var(--neutral-300);
    padding: 2px 10px;
    border-radius: 30px;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 12px;
    color: var(--neutral-700);
    cursor: default;
  }

  .tooltip-more {
    text-align: left;
    padding: 5px 0;
  }
.icon {
  width: 22px;
  fill: var(--blue-500);
}
.icon2 {
  width: 25px;
  fill: var(--blue-500);
}
.icon3 {
  width: 18px;
  fill: var(--blue-500);
}
.type {
  color: var(--blue-500);
}
.pipe {
  width: 1px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--blue-500);
}
.name-wrapper {
  position: relative;
  width: 100%;
  .name-ellipsis {
    position: absolute;
    top: -10px;
    left: 0;
    right: 20px;
    color: var(--type-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
