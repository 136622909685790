<template>
  <div>
    <b-table-simple class="p-table">
      <thead>
        <tr>
          <td class="td-header situation">Situação</td>
          <td class="td-header">Data</td>
          <td class="td-header situation2">Horário</td>
          <td class="td-header situation2">Profissional</td>
          <td class="td-header">Itens</td>
          <td class="td-header situation">Convênio</td>
          <td class="td-header"></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="appointment in appointments" :key="appointment.id" >
          <td class="td-body td-simple status">
            <span class="option scheduled" v-if="appointment.status == 'SCHEDULED'">
              Agendado
            </span>
            <div class="option confirmed" v-else-if="appointment.status == 'CONFIRMED'">
              Confirmado
            </div>
            <div class="option confirmed" v-else-if="appointment.status == 'CONFIRMED_BY_PATIENT'">
              Confirmado pelo paciente
            </div>
            <div class="option attend" v-else-if="appointment.status == 'ATTEND'">
              Compareceu
            </div>
            <div class="option waiting" v-else-if="appointment.status == 'WAITING'">
              Aguardando atendimento
            </div>
            <div class="option missed" v-else-if="appointment.status == 'MISSED'">
              Faltou
            </div>
            <div class="option cancelled" v-else-if="appointment.status == 'CANCELLED'">
              Cancelado
            </div>
            <div class="option cancelled" v-else-if="appointment.status == 'CANCELLED_BY_PATIENT'">
              Cancelado pelo paciente
            </div>
            <div class="option happening" v-else-if="appointment.status == 'HAPPENING'">
              Em atendimento médico
            </div>
            <div class="option examinating" v-else-if="appointment.status == 'EXAMINATING'">
              Em exames
            </div>
            <div class="option dilating" v-else-if="appointment.status == 'DILATING'">
              Dilatando
            </div>
            <div class="option finished" v-else-if="appointment.status == 'FINISHED'">
              Atend. finalizado
            </div>
            <div class="option in_pre_consultation" v-else-if="appointment.status == 'IN_PRE_CONSULTATION'">
              Em pré-consulta
            </div>
            <div class="option pre_finished" v-else-if="appointment.status == 'PRE_FINISHED'">
              Pré-consulta finalizada
            </div>
          </td>
          <td class="td-body td-simple">{{ appointment.start_datetime ? moment(appointment.start_datetime).format('DD/MM/YYYY') : '-' }}</td>
          
          <td class="td-body td-simple">
            <div>{{ moment(appointment.start_datetime).format('HH:mm') }} - {{ moment(appointment.end_datetime).format('HH:mm')}}</div>
            </td>

          <td class="td-body td-simple">{{ appointment.professional ? appointment.professional.name : '-'}}</td>

          <td class="td-body td-simple">
            <MultipleItemLine :items="appointment.appointment_items" :teste="appointment.id" />
          </td>
          <td class="td-body td-simple">
            <div class="name-wrapper">
              <div class="name-ellipsis">
                {{ appointment.appointment_items[0]?.health_plan.company_name }}
              </div>
            </div>
          </td>
          <td class="td-body td-simple">
            <b-button
              id="calendar-button"
              class="calendar-button"
              variant="outline-primary"
              
              @click="editAppointment(appointment)"
            >
              <v-calendar class="calendar-icon"/>
            </b-button>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </div>
</template>
<script>
import { parseType } from '@/utils/itemHelper'
import MultipleItemLine from '@/components/General/MultipleItemLine'
import Calendar from '@/assets/icons/calendar.svg'

export default {
  props: {
    appointments: { type: Array, required: true },
  },
  components: {
    MultipleItemLine,
    'v-calendar': Calendar,
  },

  methods: {
    parseType,

    editAppointment(appointment) {
      this.$bvModal.hide('appointment-modal')
      this.$emit('openAppointment', appointment.id);
    },
  }
}
</script>
<style lang="scss" scoped>
.calendar-icon {
  width: 24px;
  height: 24px;
  fill: white;
  background: #305BF2;
}

.calendar-button {
  padding: 10px;
  border-radius: 8px;
  background: #305BF2 !important;
}

.p-table {
  width: 100%;
  text-align: left;
  margin: 20px 0;

  thead {
    background-color: var(--neutral-100);
    .situation {
      border-radius: 0;
      width: 25%;
    }
    td {
      &:first-child {
        border-radius: 8px 0 0 0 !important;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }

  }

  tbody {
    tr {

      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 8px;
          }
          &:last-child {
            border-radius: 0 0 8px 0;
          }
        }
      }
      &:nth-child(even) {
        background-color: var(--neutral-70);
      }
    }
  }

  .td-header {
    font-weight: 700;
    font-size: 14px;
    color: var(--dark-blue);
    border: none;
    padding: 0.75rem;
  }

  .situation{
    width: 25%;
  }

  .situation2{
    width: 20%;
  }
      
  .td-body {
    flex-direction: row;
    padding: 1.5rem 0.8rem;
    border-top: 1px solid var(--neutral-300);
    font-weight: 600;
    font-size: 16px;
    color: var(--type-active);

    tr {
      border-left: 1px solid var(--neutral-300);
    }
    .multiple-item-line-wrapper {
      width: auto !important;
    }
  }

  .td-simple {
    font-weight: normal !important;
    vertical-align: middle;

    .status {
      padding: 0;
    }
  }

  .name-wrapper {
    position: relative;
    width: 100%;

    .name-ellipsis {
      color: var(--type-active);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .option{
    width: fit-content;
    flex-direction: row;
    padding: 8px 16px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .scheduled {
    background: #FFFFFF;
    border: 1px solid #525C7A;
    border-radius: 100px;
    color: #525C7A;
  }

  .confirmed {
    background: #F2FFE6;
    border-radius: 100px;
    color: #046340;
  }

  .attend {
    background: #E6F5FF;
    border-radius: 100px;
    color: #0B30B2;
  }

  .waiting {
    background: #FFF6D4;
    border-radius: 100px;
    color: #80430B;
  }

  .happening {
    background: #FFF3E1;
    border-radius: 100px;
    color: #FF6B00;
  }

  .examinating {
    background: #FFE3F1;
    border-radius: 100px;
    color: #6C3350;
  }

  .dilating {
    background: #FBF0FF;
    border-radius: 100px;
    color: #673270;
  }

  .in_pre_consultation {
    background: #E4FAFF;
    border-radius: 100px;
    color: #008E75;
  }

  .pre_finished {
    background: #FFEFE4;
    border-radius: 100px;
    color: #613F26;
  }

  .missed {
    background: #FFF7F7;
    border-radius: 100px;
    color: #D82828;
  }

  .cancelled {
    background: #5266AF;
    border-radius: 100px;
    color: #F8FDFF;
  }

  .finished {
    background: #F4F7FF;
    border: 1px solid #304388;
    border-radius: 100px;
    color: #304388;
  }
}
</style>