<template>
  <div class="appointment-history">
    <div class="results-row">
      <div >
        <b-form-group class="form-group">
          <label for="search">Item</label>

            <multiselect
            placeholder="Selecionar..."
            v-model="item"
            class="with-border status"
            :options="items"
            :showLabels="false"
            :internalSearch="false"
            :searchable="true"
            @search-change="debounceItems"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <ItemLine :item="option" />
              </template>
              <template slot="option" slot-scope="props">
                <ItemLine :item="props.option"/>
                <p class="disabled-description" v-if="props.option.$isDisabled">
                  Este procedimento é realizado em outra sala, crie um novo agendamento para adicioná-lo.
                </p>
              </template>
              <template slot="noResult">Nenhum procedimento encontrado ou procedimento não disponível na sala</template>
              <template slot="noOptions">
                Digite para pesquisar um procedimento
              </template>
            </multiselect>
        </b-form-group>
      </div>

      <div >
        <b-form-group class="form-group">
          <label for="search" id="input-title">Profissional</label>
          <v-autocomplete
            placeholder="Profissional"
            v-model="professionalId"
            :options="doctors"
            :disabled="userHasRole('DOCTOR')"
          />
        </b-form-group>
      </div>

      <div >
        <b-form-group class="form-group">
          <label for="search" id="input-title">Situação</label>
          <multiselect
            placeholder="Selecionar..."
            v-model="status"
            class="with-border status"
            :options="statusOptions"
            :showLabels="false"
            :internalSearch="false"
            :searchable="false"
            @select="handleSituation"
            >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="option" slot-scope="{ option }">
              <span class="option" >
                <div :class="`situation ${option.value.toLowerCase()}`"/>
                {{option.label}}
              </span>
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              <span class="option" >
                <div :class="`situation ${option.value.toLowerCase()}`"/>
                {{option.label }}
              </span>
            </template>

            <template slot="noOptions">
              Nenhuma opção
            </template>

            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>
        </b-form-group>
      </div>

      <div>
        <b-form-group class="form-group">
          <v-periods
            label="Período"
            @select="handlePeriod"
          />
        </b-form-group>
      </div>
    </div>

    <div class="results-row">
      <div>
        <v-simple-card
          class="card"
          title="Quantidade de agendamentos"
          color="#305BF2"
          :value="total"
        />
      </div>
      <div>
        <v-simple-card
          class="card"
          title="Atendimentos finalizados"
          color="#097C53"
          :value="finalizado"
        />
      </div>
      <div>
        <v-simple-card
          class="card"
          title="Quantidade de faltas"
          color="#F63220"
          :value="faltas"
        />
      </div>
      <div>
        <v-simple-card
          class="card"
          title="Quantidade de cancelamentos"
          color="#0C1D59"
          :value="cancelados"
        />
      </div>
    </div>
    <div>
      <v-history-table
      :appointments="appointments"
      @openAppointment="openAppointment"
      />
    </div>
    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>
  </div>
</template>
<script>
import { userHasRole, getCurrentUser} from '@/utils/localStorageManager'
import Autocomplete from '@/components/Autocomplete.vue'
import Periods from '@/components/General/Periods'
import SimpleCard from '@/components/Dashboard/SimpleCard'
import AppointmentHistoryTableVue from '../Warehouse/AppointmentHistoryTable.vue';
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ItemLine from '@/components/General/ItemLine'
import { debounce } from 'lodash';

export default {
  props: {
    patient: Object,
    disabled: Boolean,
  },

  components: {
    'v-autocomplete': Autocomplete,
    'v-periods': Periods,
    'v-simple-card': SimpleCard,
    'v-history-table': AppointmentHistoryTableVue,
    ChevronDown,
    ItemLine,

  },
  created() {
    this.getResumeValues();
    this.getByFilters();
    this.getDoctors();
    if(process.env.NODE_ENV === 'local') this.inLocal = true
  },
  data() {
    return {
      user: getCurrentUser(),
      clinic: JSON.parse(localStorage.getItem('clinic')),
      validated: false,
      professionalId: null,
      doctors: [],
      query: '',
      start: null,
      end: null,
      total: 0,
      finalizado: 0,
      faltas: 0,
      cancelados: 0,
      appointments: [],
      currentPage: 1,
      perPage: 5,
      totalItems: 0,
      items: [],
      item: [],
      debounceItems: debounce(this.searchItems, 300),
      status: null,
      statusOptions: [
        {value:'Todos', label: 'Todos'},
        {value:'SCHEDULED', label: 'Sem situação'},
        {value:'CONFIRMED', label: 'Confirmado'},
        {value:'ATTEND', label: 'Compareceu'},
        {value:'WAITING', label: 'Aguardando atendimento'},
        {value:'MISSED', label: 'Faltou'},
        {value:'CANCELLED', label: 'Cancelado'},
        {value:'HAPPENING', label: 'Em atendimento médico'},
        {value:'EXAMINATING', label: 'Em exames'},
        {value:'DILATING', label: 'Dilatando'},
        {value:'FINISHED', label: 'Finalizado'},
        {value:'PRE_FINISHED', label: 'Pré-consulta finaliza'},
        {value:'IN_PRE_CONSULTATION', label: 'Em pré-consulta'}
      ]
    }
  },
  methods: {
    userHasRole,

    searchItems(query) {
      this.api.getItems(this.clinic.id, query)
      .then(res => {
        this.items = res.data
        this.disableProcedures()
      })
      .catch(err => this.$toast.error(err.message))
    },

    disableProcedures() {
      if (!this.availableProcedures || !this.availableProcedures.length) return
      this.items = this.items.map(el => ({ ...el, $isDisabled: this.item !== el.id && el.item_type === 'App\\Models\\ClinicProcedure' && !this.availableProcedures.some(av => av.clinic_procedure_id === el.id)}))
    },

    openAppointment(appointmentId) {
      this.$emit('openAppointment', appointmentId);
    },

    getResumeValues() {
      this.api.getResumeValues(this.patient.id)
        .then(res => {
          this.total = res.data['Quantidade de agendamentos']
          this.finalizado = res.data['Atendimentos finalizados']
          this.faltas = res.data['Quantidade de faltas']
          this.cancelados = res.data['Quantidade de cancelamentos']
        })
        .catch(err => this.$toast.error(err.message))
    },

    getByFilters() {
      this.api.getByFilters(this.patient.id,
        this.professionalId === -1 ? null : this.professionalId,
        this.start,
        this.end,
        this.status?.value === 'Todos' ? null : this.status?.value,
        this.item?.id,
        this.currentPage,
      )
        .then(res => {
          this.appointments = res.data.data
          this.currentPage = res.data.current_page
          this.perPage = res.data.per_page
          this.totalItems = res.data.total
        })
        .catch(err => this.$toast.error(err.message))
    },

    handlePeriod(value) {
      this.start = value.start;
      this.end = value.end;
    },

    handleSituation(value) {
      this.status = value
    },

    getDoctors() {
      this.api.getClinicDoctors(this.clinic.id)
        .then(({ data }) => {

          this.doctors = data.doctors.map(doctor => ({
            value: doctor.id,
            label: doctor.name,
            el: doctor
          }))

          if (userHasRole('DOCTOR')) {
            const  doctor = this.doctors.find(doctor => doctor.value === this.user.id)
            if (doctor) {
              this.professionalId = doctor.value
              this.doctors = [doctor]
            }
          }

          this.doctors.unshift({
            value: -1,
            label: 'Todos',
          })
        })
        .catch(console.log)
    },

  },
  watch: {
    currentPage: {
      handler: async function () {
        await this.getByFilters()
      }
    },
    item: function() { this.getByFilters(); },
    start: function() { this.getByFilters(); },
    status: function() { this.getByFilters(); },
    period: function() {
      this.start = this.period.start
      this.end = this.period.end
      this.getByFilters();
    },
    professionalId() {this.getByFilters();},
    'form.patient': function(patient) {
      this.$emit('setPatient', patient)
    }
  }
}
</script>
<style lang="scss" scoped>
.appointment-history {
  padding: 1.5rem 0;
  .pagination-position {
    padding: 0;
  }
  table {
    thead {
      tr {
        background-color: var(--neutral-100);
      }
    }
  }
}
.appointment-content {
  padding: 30px 0;
}
.results-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  .card {
    width: auto !important;
    padding: 1rem;
    gap: 0;

    .title {
      text-wrap: nowrap;
    }
  }
  .status {
    width: auto !important;
  }
}
</style>
